import * as React from "react";

import { UpdatePasswordUrlParams, UserLoginProps, getRedirectPageUrl } from "./userLoginWidget";

import { getI18nLocaleString } from "../../../i18n";
import { isClientSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { parse } from "query-string";

export function PasswordNearExpirationNotice(props: UserLoginProps) {
    const { context } = props;
    const daysTillPasswordExpires = getUpdatePasswordUrlParams()?.daysTillPasswordExpires || "0";

    return (
        <React.Fragment>
            <div className="alert alert-warning feedback">
                <label>
                    {getI18nLocaleString(namespaceList.userLoginWidget, +daysTillPasswordExpires < 1 ? "passwordExpiredNotice" : "passwordWillExpireNotice", context.currentLocale).replace(
                        new RegExp("\\$daysTillPasswordExpires"),
                        daysTillPasswordExpires
                    )}
                </label>
            </div>
        </React.Fragment>
    );
}

export function NotNowPageLink(props: UserLoginProps) {
    const { context } = props;
    const { currentLocale, site } = context;
    const [myEnvLandingPageUrl, setMyEnvLandingPageUrl] = React.useState<string>();

    React.useEffect(() => {
        getMyEnvLandingPageUrl(props).then((url) => setMyEnvLandingPageUrl(url));
    }, []);

    const btnTitle = getI18nLocaleString(namespaceList.admin, "updatePasswordNotNow", currentLocale, site);
    return (
        <div className="update-password-not-now">
            <a className="button-link" href={myEnvLandingPageUrl} title={btnTitle}>
                <span>{btnTitle}</span>
                <span className="fa fa-angle-right" />
            </a>
        </div>
    );
}

async function getMyEnvLandingPageUrl(props: UserLoginProps): Promise<string | undefined> {
    const { context, options } = props;
    const isOwnerUrlParam = getUpdatePasswordUrlParams()?.isOwner || "false";
    if (isOwnerUrlParam.toLowerCase() === "true" && options.localizedOwnerRedirectPageLink?.length) {
        return await getRedirectPageUrl(options.localizedOwnerRedirectPageLink, context);
    }
    return await getRedirectPageUrl(options.localizedPageLink, context);
}

function getUpdatePasswordUrlParams(): UpdatePasswordUrlParams | undefined {
    return isClientSide() ? (parse(window.location.search) as UpdatePasswordUrlParams) : undefined;
}
