import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { LocalizedPageLink } from "../userLogin/index";
import { WidgetOptions } from "../guestInterfaceWidget";
import { WithId } from "@maxxton/cms-api";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { globalApiContext } from "../../../containers/CmsProvider";
import { isLocal } from "../../../components/utils";
import namespaceList from "../../../i18n/namespaceList";

export type RuleNames = "minLength" | "specialChar" | "digit" | "capital" | "match" | "lowercase" | "notEmpty" | "prohibitedChars";

export type ForgotPasswordWidgetOptions = Pick<WidgetOptions, "localizedPageLink" | "localizedFormOptions"> & WithId & Partial<Pick<WidgetOptions, "siteId" | "pageId">>;

export class dynamicFormUtil {
    public static async handleForgotPasswordFlow(params: { context: CMSProviderProperties; options: ForgotPasswordWidgetOptions; email: string; reCaptchaToken: string }): Promise<boolean> {
        const { context, options, email, reCaptchaToken } = params;
        const { localizedPageLink } = options;
        const { currentLocale, site } = context;
        const targetLocalizedPageLink: LocalizedPageLink | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedPageLink || [] });
        // TODO: once all widget configs use the localizedPage link. Then we can remove the options.siteId and options.pageId code here.
        const siteId = targetLocalizedPageLink?.siteId || options.siteId;
        const pageId = targetLocalizedPageLink?.pageId || options.pageId;
        if (!pageId || !siteId) {
            globalApiContext().logger.info("No target page configured inside the forgot password widget.");
            return false;
        }
        const env = await getMxtsEnv(context, context.currentLocale.code);
        return context.mxtsApi
            .sendForgotPasswordEmail(env, {
                reCaptchaToken,
                siteHost: location.host,
                formOptionId: ((options as any) as { _id: string })._id,
                localeId: currentLocale.locale,
                email,
                siteId,
                pageId,
                httpsResetLink: context.site.hasSecurityCertificate && !isLocal(),
                localeCode: !(site.locale.code === currentLocale.code) ? currentLocale.code : "",
            })
            .catch((error) => {
                globalApiContext().logger.error(`Error while sending forgot password email: ${error}`);
                return false;
            });
    }

    public static passwordChecklist(
        value: string,
        confirmValue: string,
        context: CMSProviderProperties
    ): {
        [key in RuleNames]: { valid: boolean; message: string };
    } {
        const { currentLocale, site } = context;

        const ruleDefinitions = {
            minLength: {
                valid: value.length >= 12,
                message: getI18nLocaleString(namespaceList.userLoginWidget, "minLengthValidation", currentLocale, site),
            },
            specialChar: {
                valid: /[~¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|\\":<>?._]/g.test(value),
                message: getI18nLocaleString(namespaceList.userLoginWidget, "specialCharValidation", currentLocale, site),
            },
            digit: {
                valid: /\d/g.test(value),
                message: getI18nLocaleString(namespaceList.userLoginWidget, "digitValidation", currentLocale, site),
            },
            capital: {
                valid: value.toLowerCase() !== value,
                message: getI18nLocaleString(namespaceList.userLoginWidget, "capitalValidation", currentLocale, site),
            },
            lowercase: {
                valid: value.toUpperCase() !== value,
                message: getI18nLocaleString(namespaceList.userLoginWidget, "lowercaseValidation", currentLocale, site),
            },
            match: {
                valid: value.length > 0 && value === confirmValue,
                message: getI18nLocaleString(namespaceList.userLoginWidget, "matchValidation", currentLocale, site),
            },
            prohibitedChars: {
                valid: !/[`]/g.test(value),
                message: getI18nLocaleString(namespaceList.userLoginWidget, "prohibitedChars", currentLocale, site),
            },
            notEmpty: {
                valid: Boolean(value.length > 0 && confirmValue && confirmValue.length > 0),
                message: getI18nLocaleString(namespaceList.userLoginWidget, "notEmptyValidation", currentLocale, site),
            },
        };
        return ruleDefinitions;
    }
    public static getParamsFromFilter(dynamicFilter: DynamicFilter): UrlLinkParams {
        const params: UrlLinkParams = {};
        if (dynamicFilter.stateUuid) {
            params.stateUuid = dynamicFilter.stateUuid;
            return params;
        }
        return UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter, { includeMinBedBathZero: true });
    }
}
