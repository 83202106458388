import * as React from "react";

import { FormSpec, SomeInputSpec, formSpec, localized, multiSelectStylePicker, pageSpec, templateSpec } from "../../../form-specs";
import { LocalizedContentBase, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, Widget } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { UserLoginWidget } from "./userLoginWidget";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getFormField } from "../guestInterfaceWidget";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface SessionExpiredFallback {
    sessionExpiredFallbackWebContentId?: string;
    sessionExpiredFallbackTemplateId?: string;
    sessionExpiredFallback?: boolean;
}
export interface WidgetOptions extends SessionExpiredFallback, WithId {
    styleIds: string[];
    bookingModuleForm: string;
    fillCustomerDetails: boolean;
    localized: LocalizedButtonOptions[];
    forgotPasswordInterface: boolean;
    updatePasswordInterface: boolean;
    loginAfterUpdatePassword: boolean;
    updatePasswordAfterUserLoggedIn: boolean;
    localizedFormOptions?: LocalizedFormOptions[];
    localizedPageLink?: LocalizedPageLink[];
    localizedOwnerRedirectPageLink?: LocalizedPageLink[];
    localizedExpiringPasswordPageLink?: LocalizedPageLink[];
    loginSuccessTemplate?: string;
    isBookingFlowLoginRedirect?: boolean;
    useAsTouroperatorAccountCreation?: boolean;
    tourOperatorAccountCreationForm?: string;
}

export interface LocalizedButtonOptions extends LocalizedContentBase {
    buttonText: string;
    passwordUpdated?: string;
    loginPageLinkLabel?: string;
}

export interface LocalizedPageLink extends LocalizedContentBase {
    siteId?: string;
    pageId?: string;
}

export interface LocalizedFormOptions {
    locale: string;
    template: string;
    emailSender: string;
    emailSenderFriendlyName: string;
    emailSubject: string;
    feedbackMessage: string;
    enableReCaptcha: boolean;
    siteId: string;
    pageId: string;
}

const TARGETS = ["user-login"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "userLoginWidget",
    name: getI18nLocaleObject(namespaceList.userLoginWidget, "userLoginWidget"),
    pluralName: getI18nLocaleObject(namespaceList.userLoginWidget, "userLoginWidgets"),
    properties: [
        /* jscpd:ignore-start */
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "selectForm"),
                                variable: "bookingModuleForm",
                                type: "autocomplete",
                                refType: formSpec,
                                visible: (options: WidgetOptions) => !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "fillCustomerDetails"),
                                variable: "fillCustomerDetails",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                variable: "loginSuccessTemplate",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                type: "autocomplete",
                                refType: templateSpec,
                                visible: (options: WidgetOptions) => options.fillCustomerDetails && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "forgotPasswordInterface"),
                                variable: "forgotPasswordInterface",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.updatePasswordInterface && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "updatePasswordInterface"),
                                variable: "updatePasswordInterface",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.forgotPasswordInterface && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "loginAfterUpdatePassword"),
                                variable: "loginAfterUpdatePassword",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.updatePasswordInterface && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "updatePasswordAfterUserLoggedIn"),
                                variable: "updatePasswordAfterUserLoggedIn",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.updatePasswordInterface && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "useAsTouroperatorAccountCreation"),
                                variable: "useAsTouroperatorAccountCreation",
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    !options.forgotPasswordInterface && !options.loginAfterUpdatePassword && !options.updatePasswordInterface && !options.fillCustomerDetails,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "selectForm"),
                                variable: "tourOperatorAccountCreationForm",
                                type: "autocomplete",
                                refType: formSpec,
                                visible: (options: WidgetOptions) => !!options.useAsTouroperatorAccountCreation,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "passwordUpdated",
                                        label: getI18nLocaleObject(namespaceList.userLoginWidget, "passwordUpdated"),
                                        type: "text",
                                    },
                                    {
                                        variable: "loginPageLinkLabel",
                                        label: getI18nLocaleObject(namespaceList.userLoginWidget, "loginPageLinkLabel"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.updatePasswordInterface && !options.useAsTouroperatorAccountCreation,
                            }),
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "configureLoginPageUrl"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => options.updatePasswordInterface && !options.loginAfterUpdatePassword && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.pluginForm, "configureUpdatePasswordPageUrl"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => options.forgotPasswordInterface && !options.useAsTouroperatorAccountCreation,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "configureRedirectPage"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => options.updatePasswordInterface && options.loginAfterUpdatePassword && !options.useAsTouroperatorAccountCreation,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.pluginForm, "email"),
                    properties: [
                        [
                            localized({
                                variable: "localizedFormOptions",
                                tabContent: [
                                    {
                                        variable: "template",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "template"),
                                        type: "textarea",
                                    },
                                    {
                                        variable: "emailSender",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "emailSender"),
                                        type: "text",
                                    },
                                    {
                                        variable: "emailSenderFriendlyName",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "emailSenderFriendlyName"),
                                        type: "text",
                                    },
                                    {
                                        variable: "emailSubject",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "emailSubject"),
                                        type: "text",
                                    },
                                    {
                                        variable: "feedbackMessage",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "feedbackMessage"),
                                        type: "text",
                                    },
                                    {
                                        variable: "enableReCaptcha",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "enableReCaptcha"),
                                        type: "checkbox",
                                    },
                                ],
                            }),
                        ],
                    ],
                    visible: (options: WidgetOptions) => options.forgotPasswordInterface || !!options.useAsTouroperatorAccountCreation,
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    visible: (options: WidgetOptions) => !options.fillCustomerDetails,
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "customerLoginRedirectPage"),
                            },
                            {
                                variable: "isBookingFlowLoginRedirect",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "isBookingFlowLoginRedirect"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localizedPageLink",
                                tabContent: createRedirectPageLinkTabContents(),
                            }),
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "ownerLoginRedirectPage"),
                            },
                            localized({
                                variable: "localizedOwnerRedirectPageLink",
                                tabContent: createRedirectPageLinkTabContents(),
                            }),
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "expiringPasswordRedirectPage"),
                                visible: (options: WidgetOptions) => isMyEnvLoginPageConfig(options),
                            },
                            localized({
                                variable: "localizedExpiringPasswordPageLink",
                                tabContent: createRedirectPageLinkTabContents(),
                                visible: (options: WidgetOptions) => isMyEnvLoginPageConfig(options),
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "sessionExpiredFallback",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "sessionExpiredFallback"),
                                type: "checkbox",
                            },
                            {
                                variable: "sessionExpiredFallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "sessionExpiredFallbackWebContentId"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                                visible: (options: WidgetOptions) => !!options.sessionExpiredFallback,
                            },
                            {
                                variable: "sessionExpiredFallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.userLoginWidget, "sessionExpiredFallbackTemplateId"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                                visible: (options: WidgetOptions) => !!options.sessionExpiredFallback,
                            },
                        ],
                    ],
                },
            ],
        },
        /* jscpd:ignore-end */
    ],
};

function isMyEnvLoginPageConfig(options: WidgetOptions) {
    return !options.fillCustomerDetails && !options.forgotPasswordInterface && !options.updatePasswordInterface && !options.tourOperatorAccountCreationForm;
}

function createRedirectPageLinkTabContents(): Array<SomeInputSpec<any, string | number | symbol>> {
    return [
        {
            label: getI18nLocaleObject(namespaceList.admin, "site"),
            variable: "siteId",
            type: "autocomplete",
            default: "",
            refType: autocompleteSiteSpec,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "page"),
            variable: "pageId",
            type: "autocomplete",
            default: "",
            refType: pageSpec,
            dependsOnSiteSpec: "siteId",
        },
    ];
}

export const userLogin: PageWidgetSpec<WidgetOptions> = {
    id: "userLogin",
    type: "page",
    widgetGroup: WidgetGroup.MY_ENVIRONMENT,
    name: getI18nLocaleObject(namespaceList.userLoginWidget, "userLoginWidget"),
    description: getI18nLocaleObject(namespaceList.userLoginWidget, "userLoginWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        bookingModuleForm: "",
        fillCustomerDetails: false,
        localized: [],
        forgotPasswordInterface: false,
        updatePasswordInterface: false,
        loginAfterUpdatePassword: false,
        updatePasswordAfterUserLoggedIn: false,
        styleIds: [],
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        let dynamicFormSpec = await getFormField(widget.options.tourOperatorAccountCreationForm || widget.options.bookingModuleForm, context);
        const formFields: string[] = [];
        const { forgotPasswordInterface, updatePasswordInterface, tourOperatorAccountCreationForm, styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        if (forgotPasswordInterface || updatePasswordInterface) {
            dynamicFormSpec = {
                id: dynamicFormSpec.id,
                name: dynamicFormSpec.name,
                pluralName: dynamicFormSpec.pluralName,
                properties: dynamicFormSpec.properties.filter((formfield) => formfield.type === (forgotPasswordInterface ? "email" : "password")),
            };
        } else {
            dynamicFormSpec.properties.forEach((formfield: any) => {
                if (formfield.children) {
                    formfield.children.forEach((child: any) => {
                        formFields.push(child.variable);
                    });
                } else {
                    formFields.push(formfield.variable);
                }
            });
        }
        return <UserLoginWidget options={widget.options} context={context} dynamicFormSpec={dynamicFormSpec} mandatoryFields={[]} className={className} />;
    },
};
