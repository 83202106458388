import * as React from "react";
import * as moment from "moment";

import { Customer, MAX_PASSWORD_AGE_DAYS, PermissionType, TourOperatorCustomerCreationRequest } from "@maxxton/cms-mxts-api";
import { FormSubmitDetails, FormSubmitOptions } from "../guestInterfaceWidget/dynamicForm";
import { LocalizedPageLink, WidgetOptions } from "./";
import { NotNowPageLink, PasswordNearExpirationNotice } from "./PasswordNearExpirationNotice";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicFormWidgetOptions } from "../guestInterfaceWidget";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { RecaptchaProviderEnabler } from "../guestInterfaceWidget/recaptcha/reCaptcha.util";
import { dynamicFormUtil } from "../guestInterfaceWidget/dynamicForm.util";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isClientSide } from "../../../utils/generic.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { parse } from "query-string";
import { setOpacityOnHide } from "../../../components/utils";

const DynamicForm = loadable(() => loadableRetry(() => import("../guestInterfaceWidget/dynamicForm")), {
    resolveComponent: ({ DynamicForm }) => DynamicForm,
});

export interface UserLoginProps extends PageWidgetBaseProps<WidgetOptions> {
    context: CMSProviderProperties;
    dynamicFormSpec: any;
    mandatoryFields: string[];
    className?: string;
}

type TourOperatorCreationStatus = "LOADING" | "SUCCESS" | "FAILED" | "RECAPTCHA-FAILED" | undefined;

const PASSWORD_SOON_EXPIRING_DAYS_THRESHOLD = 31; // Specifies when should we start bugging the customer about their pw expiring

export function UserLoginWidget(props: UserLoginProps) {
    const { options, context, dynamicFormSpec, mandatoryFields, className } = props;
    const hideWidget = setOpacityOnHide(options);
    const { forgotPasswordInterface, updatePasswordInterface, tourOperatorAccountCreationForm } = options;
    const shouldUseRecaptcha = forgotPasswordInterface || tourOperatorAccountCreationForm;

    const [tourOperatorCreationStatus, setTourOperatorCreationStatus] = React.useState<TourOperatorCreationStatus>();

    const showPasswordExpiringNotice = isClientSide() && (parse(window.location.search) as UpdatePasswordUrlParams)?.daysTillPasswordExpires && updatePasswordInterface;

    const submitOptions: FormSubmitOptions | undefined = tourOperatorAccountCreationForm
        ? {
              onSubmit: (submitDetails) => createTourOperatorUser({ ...submitDetails, context, options, setTourOperatorCreationStatus }),
              includeRecaptchaToken: true,
          }
        : undefined;

    const onLoginSuccess = async (params: LoginSuccessParams) => redirectToCorrectMyEnvPage(props, params);

    const dynamicForm = (
        <React.Fragment>
            {!!showPasswordExpiringNotice && <PasswordNearExpirationNotice {...props}></PasswordNearExpirationNotice>}
            <DynamicForm
                permission={PermissionType.EXECUTE}
                className={hideWidget}
                spec={dynamicFormSpec}
                value={{}}
                options={options as DynamicFormWidgetOptions}
                alerts={context.alerts}
                context={context}
                mandatoryFields={mandatoryFields}
                submitOptions={submitOptions}
                userLoginInterface={forgotPasswordInterface || updatePasswordInterface || tourOperatorAccountCreationForm ? false : true}
                onLoginSuccess={onLoginSuccess}
                isUserLoginWidget={true}
            />
            {!!showPasswordExpiringNotice && <NotNowPageLink {...props}></NotNowPageLink>}
            <TouroperatorCreationStatus tourOperatorCreationStatus={tourOperatorCreationStatus} context={context}></TouroperatorCreationStatus>
        </React.Fragment>
    );

    return dynamicFormSpec ? <div className={className}>{shouldUseRecaptcha ? <RecaptchaProviderEnabler>{dynamicForm}</RecaptchaProviderEnabler> : dynamicForm}</div> : null;
}

export function TouroperatorCreationStatus(props: { tourOperatorCreationStatus: TourOperatorCreationStatus; context: CMSProviderProperties }) {
    const { context, tourOperatorCreationStatus } = props;

    return (
        <React.Fragment>
            {tourOperatorCreationStatus === "RECAPTCHA-FAILED" && (
                <div className="alert alert-warning feedback">
                    <span className="feedback-label">{getI18nLocaleString(namespaceList.userLoginWidget, "tourOperatorCustomerCreationFailedOnRecaptcha", context.currentLocale)}</span>
                </div>
            )}
            {tourOperatorCreationStatus === "FAILED" && (
                <div className="alert alert-warning feedback">
                    <span className="feedback-label">{getI18nLocaleString(namespaceList.userLoginWidget, "tourOperatorCustomerCreationFailed", context.currentLocale)}</span>
                </div>
            )}
            {tourOperatorCreationStatus === "LOADING" && (
                <div className="alert alert-info feedback">
                    <span className="feedback-label">{getI18nLocaleString(namespaceList.userLoginWidget, "tourOperatorCustomerCreationInProgress", context.currentLocale)}</span>
                </div>
            )}
            {tourOperatorCreationStatus === "SUCCESS" && (
                <div className="alert alert-success feedback">
                    <span className="feedback-label">{getI18nLocaleString(namespaceList.userLoginWidget, "tourOperatorCustomerCreationSucceeded", context.currentLocale)}</span>
                </div>
            )}
        </React.Fragment>
    );
}

const createTourOperatorUser = async (
    params: FormSubmitDetails & {
        context: CMSProviderProperties;
        options: WidgetOptions;
        setTourOperatorCreationStatus: React.Dispatch<React.SetStateAction<TourOperatorCreationStatus>>;
    }
) => {
    const { reCaptchaToken, draftValue, getRecaptchaToken, context, options, setTourOperatorCreationStatus: setTourOperatorCreationStatus } = params;
    let email = "";
    let firstName = "";
    let lastName = "";
    Object.keys(draftValue).forEach((key) => {
        if (key.toLowerCase() === "email") {
            email = draftValue[key];
        }
        if (key.toLowerCase() === "firstname") {
            firstName = draftValue[key];
        }
        if (key.toLowerCase() === "lastname") {
            lastName = draftValue[key];
        }
    });

    const urlSearchParams = new URLSearchParams(context.location.search);
    const bookingReferenceVoucherCode = urlSearchParams.get("bookingReferenceVoucherCode") || "";
    const arrivalDate = urlSearchParams.get("arrivalDate") || "";
    const dcCode = urlSearchParams.get("dc") || "";

    const tourOperatorCustomerCreationRequest: TourOperatorCustomerCreationRequest = {
        bookingReferenceVoucherCode,
        arrivalDate,
        dcCode,
        firstName,
        lastName,
        email,
    };

    if (!bookingReferenceVoucherCode || !arrivalDate || !dcCode || !email) {
        // eslint-disable-next-line no-console
        console.error("missing params:", tourOperatorCustomerCreationRequest);
        setTourOperatorCreationStatus("FAILED");
        return;
    }

    if (reCaptchaToken) {
        setTourOperatorCreationStatus("LOADING");
        const env = await getMxtsEnv(context, context.currentLocale.code);
        await context.mxtsApi.createTourOperatorCustomer(env, tourOperatorCustomerCreationRequest);
        const resetLinkSent = await dynamicFormUtil.handleForgotPasswordFlow({ context, options, email, reCaptchaToken });
        if (resetLinkSent) {
            setTourOperatorCreationStatus("SUCCESS");
        } else {
            setTourOperatorCreationStatus("FAILED");
        }
    } else {
        setTourOperatorCreationStatus("RECAPTCHA-FAILED");
    }
};

export interface LoginSuccessParams {
    customer: Partial<Customer>;
    targetMyEnvRedirectUrl?: string;
}
async function redirectToCorrectMyEnvPage(props: UserLoginProps, loginSuccessParams: LoginSuccessParams) {
    const { customer, targetMyEnvRedirectUrl } = loginSuccessParams;
    const daysTillPasswordExpires = getDaysTillPasswordExpires(customer);
    if (daysTillPasswordExpires && daysTillPasswordExpires < PASSWORD_SOON_EXPIRING_DAYS_THRESHOLD) {
        const updatePasswordPageUrl = await getUpdateExpiringPasswordPageUrl(props);
        if (updatePasswordPageUrl) {
            location.replace(updatePasswordPageUrl + `?daysTillPasswordExpires=${daysTillPasswordExpires}&isOwner=${customer.owner || "false"}`);
            return;
        }
    }
    if (targetMyEnvRedirectUrl) {
        location.replace(targetMyEnvRedirectUrl);
    }
}

async function getUpdateExpiringPasswordPageUrl(props: UserLoginProps): Promise<string | undefined> {
    const { context, options } = props;
    return getRedirectPageUrl(options.localizedExpiringPasswordPageLink, context);
}

export async function getRedirectPageUrl(localizedPageLinkWidgetOption: LocalizedPageLink[] | undefined, context: CMSProviderProperties): Promise<string | undefined> {
    const { currentLocale, site: contextSite } = context;
    const targetLocalizedPageLink: LocalizedPageLink | null = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: localizedPageLinkWidgetOption || [] });
    const targetSiteId = targetLocalizedPageLink?.siteId;
    const targetPageId = targetLocalizedPageLink?.pageId;
    if (targetSiteId && targetPageId) {
        const targetSite = contextSite._id === targetSiteId ? contextSite : await context.cmsApi.siteApi.findById({ id: targetSiteId, projection: { sitemap: 0 } });
        if (targetSite) {
            const pageLinkUrl = await pageLink({ site: targetSite, pageId: targetPageId, locale: context.currentLocale, context });
            return pageLinkUrl || undefined;
        }
    }
}

export function getDaysTillPasswordExpires(customer: Partial<Customer>) {
    if (customer.passwordModifiedDate) {
        const passwordModifiedDate = moment(customer.passwordModifiedDate);
        const daysOld = moment().diff(passwordModifiedDate, "days");
        return MAX_PASSWORD_AGE_DAYS - daysOld;
    }
}

export interface UpdatePasswordUrlParams {
    resetToken?: string;
    daysTillPasswordExpires?: string;
    isOwner?: string;
}
